import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import confettiSound from "../../sounds/confetti.mp3";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import backgroundMusic from "../../music/backgroundMusic.mp3";

import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
import correctAnsSound from "../../sounds/answer-correct.mp3";
import cheerSound from "../../sounds/battle-won.mp3";
// import  LivestreamAnswerBox from "../../components/creator/LivestreamAnswerBox";
import Confetti from "react-confetti";
// import  { useWindowSize } from "../../util/hooks";
import CommentSection from "../../components/creator/CommentSection";
import PieTimer from "../../components/creator/PieTimer";
import { CustomerAuthContext } from "../../context/customerAuth";

// import  ProductAdBox from "../../components/creator/ProductAdBox";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import PurchasePowerUpForm from "../../components/creator/PurchasePowerUpForm";
import {
  defaultPicUrl,
  getMobileOperatingSystem,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import CreateBattleModal from "../../components/creator/CreateBattleModal";
import HorAdOverlayBox from "../../components/creator/HorAdOverlayBox";
import PlayerCoinCounter from "../../components/creator/PlayerCoinCounter";
import TNReviewForm from "../../components/creator/TNReviewForm";
import { GuestAuthContext } from "../../context/guestAuth";

// import  BattleMoreOptions from "../../components/creator/MoreBattleOptions";
import useSound from "use-sound";
import BattleLeaderboard from "../../components/creator/BattleLeaderboard";
import CodeForm from "../../components/creator/CodeForm";

import MiniNavBar from "../../components/creator/MiniNavBar";
import TKLPopup from "../../components/creator/TKLPopup";
import {
  CREATE_GUEST_CUSTOMER_MUTATION,
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  FETCH_ALL_PLAYER_BATTLE_STATS_BY_BATTLE,
  FETCH_CAN_USE_EXTRA_LIFE,
  FETCH_CUR_QUESTION,
  FETCH_CUR_SELECTED_ANSWER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_BAILOUT_BY_EVENT,
  FETCH_GUEST_CUSTOMER,
  FETCH_IS_OUT_OF_LIVES,
  FETCH_LIVES_COUNT_BY_CUSTOMER,
  FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
  FETCH_PLAYER_BATTLE_TOPIC_STATS,
  FETCH_PLAYER_TOPIC_STATS,
  FETCH_QUIZ_BY_ID,
  FETCH_RELEVANT_AD,
  FETCH_RELEVANT_PRODUCT_ADS,
  FETCH_SELECTED_ANSWER_STATS,
  FETCH_SHOULD_ASK_FOR_REVIEW,
  FETCH_TN_RESULTS_FORMATTED,
  FETCH_TOPIC,
  FETCH_TRIVIA_VIEW_BY_CUSTOMER,
  FETCH_TRIVIA_WIN_BY_CUSTOMER,
  LOG_TRIVIA_SHARE_MUTATION,
  PQUIZ_UPDATED_SUBSCRIPTION,
  QUIZ_UPDATED_SUBSCRIPTION,
  TOGGLE_PAUSE_PERSONAL_QUIZ_MUTATION,
  TOGGLE_PAUSE_QUIZ_MUTATION,
} from "../../util/gqlFunctions";

function Battle(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { values, setValues } = useForm(() => {}, {
    country: "",
    inviteCode: "",
    joinedFor: 4,
  });

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const { guest, loginGuest: loginGuestContext } = useContext(GuestAuthContext);

  const { quizId } = useParams();

  const [lastQuizId, setLastQuizId] = useState(null);
  const size = useWindowSize();

  const {
    subscribeToMore,
    data: { getQuizById: quiz } = {},
    refetch: refetchQuiz,
    loading: loadingQuiz,
  } = useQuery(FETCH_QUIZ_BY_ID, {
    variables: { quizId },
  });
  const {
    refetch: refetchCustTriviaView,
    data: { getTriviaViewByCustomer: custTriviaView } = {},
    loading: loadingCustTriviaView,
  } = useQuery(FETCH_TRIVIA_VIEW_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    variables: {
      quizId,
    },
  });

  const [hasPlayedAudienceCheer, setHasPlayedAudienceCheer] = useState(false);
  const [hasPlayedConfettiSound, setHasPlayedConfettiSound] = useState(false);
  const [shouldOpenQuiz, setShouldOpenQuiz] = useState(true);

  useEffect(() => {
    if (quizId !== lastQuizId) {
      refetchQuiz({ quizId });
      refetchCustTriviaView({ quizId });
      setLastQuizId(quizId);
      setHasPlayedAudienceCheer(false);
      setHasPlayedConfettiSound(false);
      setShouldOpenQuiz(true);
    }
  }, [
    quizId,
    refetchQuiz,
    hasPlayedConfettiSound,
    hasPlayedConfettiSound,
    setHasPlayedConfettiSound,
    setHasPlayedAudienceCheer,
    refetchCustTriviaView,
    lastQuizId,
    setShouldOpenQuiz,
  ]);

  useEffect(() => {
    if (quiz) {
      document.title = "Battle - " + quiz.topicNames.join(", ") + " | TKnight";
    } else {
      document.title = "Battle | TKnight";
    }
  }, [quiz]);

  const [isRobotPlaying, setIsRobotPlaying] = useState(false);
  const [isPlayingBGMusic, setIsBGMusicPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlayingBGMusic) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlayingBGMusic]);
  useEffect(() => {
    audioRef.current.volume = 0.4;
  }, []);

  // const handleBgMusicPlayPause = (e) => {
  //   e.preventDefault();
  //   setIsBGMusicPlaying(!isPlayingBGMusic);
  // };

  // const handleRobotPlayPause = (e) => {
  //   e.preventDefault();
  //   setIsRobotPlaying(!isRobotPlaying);
  // };

  // useEffect(() => {
  //   if (quiz) {

  //   }
  // }, [subscribeToMore, quiz]);

  // const {
  //   data: { getCurQuestion: curQuestion } = {},
  //   // loading: loadingCurQuestion,
  // } = useQuery(FETCH_CUR_QUESTION, {
  //   client: adminClient,
  // });

  const {
    data: { getTriviaWinByCustomer: quizWin } = {},
    loading: loadingQuizWin,
    refetch: refetchQuizWinByCustomer,
  } = useQuery(FETCH_TRIVIA_WIN_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    variables: {
      quizId,
    },
  });

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const {
    subscribeToMore: subscribeToMorePQuiz,
    data: { getPersonalQuizByQuizId: personalQuiz } = {},
    loading: loadingPersonalQuiz,
  } = useQuery(FETCH_PERSONAL_QUIZ_BY_QUIZ_ID, {
    variables: { quizId: quizId || "" },
    client: targetCustomer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (targetCustomer && targetCustomer.username === "humpty") {
      setIsRobotPlaying(false);
      setIsBGMusicPlaying(false);
    }
  }, [targetCustomer]);

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);
  const [shouldOpenQRCodeBox, setShouldOpenQRCodeBox] = useState(false);
  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const stripe = useStripe();
  const elements = useElements();

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!quiz) {
      return;
    }
    var textToShare = "Play TKnight!\n\n";

    textToShare += "It's the most fun trivia game ever!!\n\n";
    if (targetCustomer) {
      textToShare += "Extra ❤️ Code: " + targetCustomer.username + "\n\n";
    }

    textToShare += "https://tknight.live";

    if (navigator.share) {
      navigator
        .share({
          title: "Play TKnight!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };
  const [tempElapsedTime, setTempElapsedTime] = useState(null);
  const [isPaused, setIsPaused] = useState(
    personalQuiz || quiz ? (personalQuiz || quiz).isPaused : false
  );

  const {
    data: { getLivesCountByCustomer: livesCount } = {},
    loading: loadingLivesCount,
    refetch: refetchLivesCount,
  } = useQuery(FETCH_LIVES_COUNT_BY_CUSTOMER, {
    client: customerClient,
  });

  const {
    data: { getShouldAskForReview: shouldAskForReview } = {},
    // loading: loadingCustomerReview,
  } = useQuery(FETCH_SHOULD_ASK_FOR_REVIEW, {
    client: customer ? customerClient : guestClient,
    variables: { askingForLoc: 1, siteId: 0 },
  });

  useEffect(() => {
    if (quiz && (quiz.status === 2 || quiz.status === 3)) {
      refetchCustomer();
    }
  }, [quiz, refetchCustomer]);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMoreBattleOptions, setShouldShowMoreBattleOptions] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  // const shareTNStats = async (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  const [subPopupReason, setSubPopupReason] = useState(null);

  const {
    data: { getPlayerTopicStatsByTopic: playerTopicStats } = {},
    // loading: loadingTopicStats,
  } = useQuery(FETCH_PLAYER_TOPIC_STATS, {
    client: customer ? customerClient : guestClient,
    variables: {
      topicId: quiz ? quiz.topicIds[0] : "",
    },
  });

  const {
    refetch: refetchPlayerBattleTopicStatsByBattleAndTopic,
    data: {
      getPlayerBattleTopicStatsByBattleAndTopic:
        playerBattleTopicStatsByBattleAndTopic,
    } = {},
    // loading: loadingPlayerBattleTopicStatsByBattleAndTopic,
  } = useQuery(FETCH_PLAYER_BATTLE_TOPIC_STATS, {
    client: targetCustomer ? customerClient : guestClient,
    variables: {
      topicId: quiz ? quiz.topicIds[0] : "",
      quizId,
    },
  });

  const {
    data: { getCustomerBailOutByQuiz: bailOutByQuiz } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_CUSTOMER_BAILOUT_BY_EVENT, {
    client: customerClient,
    // fetchPolicy: "cache-and-network",

    variables: { quizId: quizId || "" },
  });

  const {
    refetch: refetchCurQ,
    data: { getCurQuestion: curQuestion } = {},
    // loading: loadingCurQuestion,
  } = useQuery(FETCH_CUR_QUESTION, {
    client: customer ? customerClient : guestClient,
    variables: { quizId },
  });

  const {
    // refetch: refetchRegularHorAd,
    data: { getRelevantAd: regularHorAd } = {},
    // loading: loadingCurQuestion,
  } = useQuery(FETCH_RELEVANT_AD, {
    client: customer ? customerClient : guestClient,
    variables: { format: 6, siteId: 0 },
  });

  const { data: { getRelevantAd: squareAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 8, siteId: 0 },
    }
  );

  const {
    data: { getAdBookmarkByCustomer: regularHorAdBookmark } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: { adId: regularHorAd ? regularHorAd.id : "" },
  });

  const {
    data: { getAdBookmarkByCustomer: squareAdBookmark } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: {
      adId: squareAd ? squareAd.id : "",
    },
  });

  const {
    refetch: refetchNarrowHorAd,
    data: { getRelevantAd: narrowHorAd } = {},
  } = useQuery(FETCH_RELEVANT_AD, {
    client: customer ? customerClient : guestClient,
    variables: { format: 0, siteId: 0 },
  });
  const {
    data: { getAdBookmarkByCustomer: narrowAdBookmark } = {},
    // loading: loadingBailOutByTN,
  } = useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: {
      adId: narrowHorAd ? narrowHorAd.id : "",
    },
  });

  const {
    data: { getSelectedAnswerStatsByQuestion: selectedAnswerStats } = {},
    loading: loadingSelectedAnswerStats,
    refetch: refetchSelectedAnswerStats,
  } = useQuery(FETCH_SELECTED_ANSWER_STATS, {
    client: customer ? customerClient : guestClient,
    // fetchPolicy: "cache-and-network",

    variables: { quizId, questionId: curQuestion ? curQuestion.id : "" },
  });

  const { data: { getCurSelectedAnswer: selectedAnswer } = {} } = useQuery(
    FETCH_CUR_SELECTED_ANSWER,
    {
      client: targetCustomer ? customerClient : guestClient,

      variables: { quizId, triviaNightId: null },
    }
  );
  const [lastAnsweredQ, setLastAnsweredQ] = useState(null);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(true);
  const [playCorrectAnswerSound] = useSound(correctAnsSound, { volume: 0.2 });
  // const [playWrongAnswerSound] = useSound(wrongAnsSound, { volume: 0.5 });
  const [
    playConfettiSound,
    { stop: stopConfettiSound, isPlaying: isPlayingConfettiSound },
  ] = useSound(confettiSound, { volume: 0.1 });
  const [playCheerSound, { stop: stopCheer, isPlaying: isPlayingCheer }] =
    useSound(cheerSound, {
      volume: 0.2,
    });
  const [hasMutedSound, setHasMutedSound] = useState(false);

  useEffect(() => {
    if (
      (!shouldPlaySoundEffects ||
        ((isPlayingCheer || isPlayingConfettiSound) &&
          !shouldPlaySoundEffects)) &&
      !hasMutedSound
    ) {
      stopConfettiSound();
      stopCheer();
      setHasMutedSound(true);
    }
  }, [
    shouldPlaySoundEffects,
    stopConfettiSound,
    isPlayingCheer,
    stopCheer,
    setHasMutedSound,
    hasMutedSound,
    isPlayingConfettiSound,
  ]);

  useEffect(() => {
    if (
      shouldPlaySoundEffects &&
      (personalQuiz || quiz) &&
      (personalQuiz || quiz).status === 3 &&
      (personalQuiz || quiz).lastCompletedQ !== lastAnsweredQ &&
      selectedAnswer &&
      selectedAnswerStats
    ) {
      if (selectedAnswer.isCorrect) {
        setLastAnsweredQ(selectedAnswer.questionId);
        playCorrectAnswerSound();
      } else {
        setLastAnsweredQ(selectedAnswer.questionId);
      }
    }
  }, [
    shouldPlaySoundEffects,
    selectedAnswer,
    personalQuiz,
    quiz,

    lastAnsweredQ,
    playCorrectAnswerSound,
    selectedAnswerStats,
  ]);

  const {
    data: { getRelevantProductAds: productAds } = {},
    // loading: loadingProductAds,
    refetch: refetchProductAds,
  } = useQuery(FETCH_RELEVANT_PRODUCT_ADS, {
    client: customer ? customerClient : guestClient,

    // variables: { quizId: quiz.id },
  });

  const {
    data: { getTNResultsFormatted: tnResultsFormatted } = {},
    refetch: refetchTNResultsFormatted,
  } = useQuery(FETCH_TN_RESULTS_FORMATTED, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { quizId: quizId || "" },
  });

  useEffect(() => {
    let subscription = null;
    if (quizId !== lastQuizId) {
      if (subscription) {
        subscription.unsubscribe();
      }
      subscription = subscribeToMore({
        document: QUIZ_UPDATED_SUBSCRIPTION,
        variables: { quizId: quizId || "" },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedQuiz = subscriptionData.data.quizUpdated;

          return {
            ...prev,
            getQuizById: { ...updatedQuiz },
          };
        },
      });
    }

    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, [subscribeToMore, quiz, quizId, lastQuizId]);

  useEffect(() => {
    let unsubscribe = null;
    if (personalQuiz) {
      unsubscribe = subscribeToMorePQuiz({
        document: PQUIZ_UPDATED_SUBSCRIPTION,
        variables: { personalQuizId: personalQuiz.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const updatedPersonalQuiz = subscriptionData.data.personalQuizUpdated;

          return {
            ...prev,
            getPersonalQuizByQuizId: { ...updatedPersonalQuiz },
          };
        },
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [subscribeToMorePQuiz, personalQuiz]);

  // useEffect(() => {
  //   if (!isRobotPlaying) {
  //     cancelRef.current();
  //   }
  // }, [cancelRef, isRobotPlaying]);

  const { data: { getTopicById: mainTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: quiz ? quiz.topicIds[0] : "" },
  });

  const [powerUpToPurchase, setPowerUpToPurchase] = useState(null);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);

  const [subscriptionType, setSubscriptionType] = useState(null);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenAdPopup, setShouldOpenAdPopup] = useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);

  const [shouldShowBattleLeaderboard, setShouldShowBattleLeaderboard] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [logTriviaShare, { loading: loadingLogTriviaShare }] = useMutation(
    LOG_TRIVIA_SHARE_MUTATION,
    {
      update(_, { data: { logTriviaShare: triviaShare } }) {
        setErrors({});
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  const [createGuestCustomer, { loading: loadingCreateGuestCustomer }] =
    useMutation(CREATE_GUEST_CUSTOMER_MUTATION, {
      update(_, { data: { createGuestCustomer: guestCustomer } }) {
        setErrors({});
        if (
          !guest &&
          !targetCustomer &&
          !targetGuestCustomer &&
          guestCustomer.token
        ) {
          loginGuestContext({ token: guestCustomer.token });
        }
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log(errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },

      client: targetCustomer ? customerClient : guestClient,
    });

  const [targetTopic, setTargetTopic] = useState(null);

  const togglePurchasePowerUpForm = (powerUpToPurchase) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setPowerUpToPurchase(powerUpToPurchase);
  };

  const toggleAdPopup = (shouldOpen) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenAdPopup(shouldOpen);
  };
  const [viewedReviewForm, setViewedReviewForm] = useState(false);

  useEffect(() => {
    let timeout;
    if (shouldOpenAdPopup) {
      timeout = setTimeout(() => {
        if (shouldOpenAdPopup) {
          toggleAdPopup(false);
        }
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldOpenAdPopup]);

  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };

  const togglePaymentSuccessfulForm = () => {
    setPowerUpToPurchase(null);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const toggleBuySubscriptionForm = useCallback(
    (subscriptionType) => {
      setShouldOpenSubPaymentSuccessfulForm(false);
      setPowerUpToPurchase(null);
      setShouldOpenPaymentSuccessfulForm(false);
      setShouldOpenReviewSentForm(false);
      setShouldOpenCoinPurchaseSuccessfulForm(false);
      setShouldOpenBuyCoinsForm(false);
      setShouldOpenAdPopup(false);
      setShouldShowMoreBattleOptions(false);
      setShouldShowBattleLeaderboard(false);
      setShouldOpenProInviteCodeForm(false);
      setShouldOpenJoinedProPlanPopup(false);
      setTargetTopic(null);

      setSubscriptionType(subscriptionType);

      if (
        subscriptionType === null &&
        !viewedReviewForm &&
        !shouldAskForReview &&
        quiz &&
        (quiz.status === 0 || quiz.status === 5)
      ) {
        setShouldOpenReviewForm(true);
      } else {
        setShouldOpenReviewForm(false);
        setViewedReviewForm(true);
      }
    },
    [viewedReviewForm, quiz, shouldAskForReview]
  );

  const toggleProInviteCodeForm = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleJoinedProPlanPopup = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setShouldOpenProInviteCodeForm(false);
    setTargetTopic(null);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);
    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);

  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);
    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };
  const toggleShowQRCode = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMoreBattleOptions(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);

    setTargetTopic(null);

    if (shouldOpenQuiz) {
      setShouldOpenQRCodeBox(!shouldOpenQRCodeBox);
      if (size.width < 1024) {
        setShouldOpenQuiz(false);
      }
    } else {
      setShouldOpenQRCodeBox(!shouldOpenQRCodeBox);
    }
  }, [shouldOpenQRCodeBox, shouldOpenQuiz, size]);

  const toggleLeaderboardPopup = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setTargetTopic(null);

    setShouldShowBattleLeaderboard(!shouldShowBattleLeaderboard);
  };

  const toggleOneTopicBattleForm = (tempTopic) => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMoreBattleOptions(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldShowBattleLeaderboard(false);

    setTargetTopic(targetTopic ? null : tempTopic);
  };

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    loading: loadingGuestCustomer,
    refetch: refetchGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, { client: guestClient });

  useEffect(() => {
    if (guest && !targetGuestCustomer) {
      refetchGuestCustomer();
    }
  }, [guest, targetGuestCustomer, refetchGuestCustomer]);

  useEffect(() => {
    const delay = 4000; // 5 seconds in milliseconds
    const timer = setTimeout(() => {
      if (
        !viewedReviewForm &&
        custTriviaView &&
        custTriviaView.status === 1 &&
        (targetCustomer || targetGuestCustomer) &&
        shouldAskForReview &&
        (targetCustomer || targetGuestCustomer).numOfBattlesPlayed > 3 &&
        quiz &&
        quiz.status === 5
      ) {
        toggleReviewForm();
        setViewedReviewForm(true);
      }
    }, delay);

    // Cleanup function to clear the timeout on unmount or when dependencies change
    return () => {
      clearTimeout(timer);
    };
  }, [
    targetCustomer,
    targetGuestCustomer,
    toggleReviewForm,
    viewedReviewForm,
    shouldAskForReview,
    quiz,
    custTriviaView,
  ]);

  const [showedOverlayAdOnce, setShowedOverlayAdOnce] = useState(false);
  const {
    data: { getIsOutOfLives: isOutOfLives } = {},
    loading: loadingIsOutOfLives,
    refetch: refetchIsOutOfLives,
  } = useQuery(FETCH_IS_OUT_OF_LIVES, {
    client: targetCustomer ? customerClient : guestClient,
    pollInterval: 5000,
    variables: { quizId },
  });
  useEffect(() => {
    if (
      (personalQuiz || quiz) &&
      (((personalQuiz || quiz).status >= 5 && quizWin) ||
        (selectedAnswer &&
          selectedAnswerStats &&
          !isOutOfLives &&
          selectedAnswer.isCorrect &&
          (personalQuiz || quiz).lastCompletedQIndex ===
            (personalQuiz || quiz).numOfQs - 1)) &&
      !hasPlayedAudienceCheer &&
      shouldPlaySoundEffects
    ) {
      playConfettiSound();
      playCheerSound();
      setHasPlayedAudienceCheer(true);
    }
  }, [
    quizWin,
    personalQuiz,
    quiz,
    shouldPlaySoundEffects,
    selectedAnswer,
    hasPlayedAudienceCheer,
    playCheerSound,
    selectedAnswerStats,
    isOutOfLives,
    playConfettiSound,
  ]);

  const {
    data: { getNumOfExtraLivesNeeded: numOfExtraLivesNeeded } = {},
    loading: loadingNumOfExtraLivesNeeded,
    refetch: refetchNumOfExtraLivesNeeded,
  } = useQuery(FETCH_CAN_USE_EXTRA_LIFE, {
    client: targetCustomer ? customerClient : guestClient,
    pollInterval: 5000,
    variables: { quizId },
  });

  useEffect(() => {
    if (
      !loadingIsOutOfLives &&
      !loadingQuiz &&
      !loadingPersonalQuiz &&
      quiz &&
      ((quiz.gameTimingType === 1 && personalQuiz) ||
        quiz.gameTimingType === 0) &&
      (!targetCustomer || !targetCustomer.isPaidSubscriber)
    ) {
      if (
        (personalQuiz || quiz).status === 1 &&
        ((personalQuiz || quiz).lastCompletedQIndex === 5 ||
          (personalQuiz || quiz).lastCompletedQIndex === 1) &&
        !showedOverlayAdOnce &&
        !isOutOfLives &&
        !shouldOpenAdPopup &&
        !numOfExtraLivesNeeded
      ) {
        setShowedOverlayAdOnce(true);
        toggleAdPopup(true);
      } else if (
        (personalQuiz || quiz) &&
        (personalQuiz || quiz).status !== 1 &&
        shouldOpenAdPopup
      ) {
        setShowedOverlayAdOnce(false);
        toggleAdPopup(false);
      }
    }
  }, [
    shouldOpenAdPopup,
    quiz,
    targetCustomer,
    personalQuiz,
    isOutOfLives,
    loadingIsOutOfLives,
    targetGuestCustomer,
    showedOverlayAdOnce,
    loadingPersonalQuiz,
    loadingQuiz,
    numOfExtraLivesNeeded,
  ]);

  const {
    data: { getAllPlayerBattleStatsByBattle: allPlayerBattleStats } = {},
    // loading: loadingPlayerBattleStatsByBattle,
    refetch: refetchAllPlayerBattleStatsByBattle,
  } = useQuery(FETCH_ALL_PLAYER_BATTLE_STATS_BY_BATTLE, {
    client: customer ? customerClient : guestClient,

    variables: { quizId },
  });

  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && (targetCustomer || targetGuestCustomer)) {
      setShouldPlaySoundEffects(
        (targetCustomer || targetGuestCustomer).areSoundEffectsMuted === true
          ? false
          : true
      );
      setInitializedMuteVals(true);
    }
  }, [
    targetGuestCustomer,
    shouldPlaySoundEffects,
    initializedMuteVals,
    targetCustomer,
  ]);

  useEffect(() => {
    if (
      targetCustomer &&
      (!targetCustomer.curRegistrationStep ||
        targetCustomer.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + targetCustomer.curRegistrationStep);
    }
  }, [targetCustomer, navigate]);

  useEffect(() => {
    if (
      !targetGuestCustomer &&
      !targetCustomer &&
      !loadingCreateGuestCustomer &&
      !loadingCustomerInfo &&
      !loadingGuestCustomer &&
      !errors
    ) {
      createGuestCustomer({ variables: values });
    }
  }, [
    targetCustomer,
    targetGuestCustomer,
    loadingCreateGuestCustomer,
    loadingCustomerInfo,
    loadingGuestCustomer,
    values,
    errors,
    createGuestCustomer,
  ]);

  const [togglePauseQuiz, { loading: loadingTogglePauseQuiz }] = useMutation(
    TOGGLE_PAUSE_QUIZ_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
        {
          query: FETCH_QUIZ_BY_ID,
          variables: {
            quizId: quiz ? quiz.id : "",
          },
        },
        {
          query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
          variables: {
            quizId: quiz ? quiz.id : "",
          },
        },
      ],
      update(_, { data }) {
        setErrors({});
        setTempElapsedTime(null);

        setIsPaused(data);
      },
      onError(err) {
        setTempElapsedTime(null);

        setIsPaused(!isPaused);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
          // setIsErrMsgShown(true);
          if (err.graphQLErrors[0].extensions.errors) {
            // setAnswerErr(err.graphQLErrors[0].extensions.errors.pause);
            // setTypeOfErr(1);
          }
        }
      },
      variables: { quizId: quiz ? quiz.id : "", secondsLeftAtPause: 10 },
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  const [togglePausePersonalQuiz, { loading: loadingTogglePausePersonalQuiz }] =
    useMutation(TOGGLE_PAUSE_PERSONAL_QUIZ_MUTATION, {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
        {
          query: FETCH_QUIZ_BY_ID,
          variables: {
            quizId: quiz ? quiz.id : "",
          },
        },
        {
          query: FETCH_PERSONAL_QUIZ_BY_QUIZ_ID,
          variables: {
            quizId: quiz ? quiz.id : "",
          },
        },
      ],
      update(
        _,
        { data: { togglePausePersonalQuiz: toggledPausePersonalQuiz } }
      ) {
        setErrors({});
        setTempElapsedTime(null);

        setIsPaused(toggledPausePersonalQuiz);
      },
      onError(err) {
        setTempElapsedTime(null);

        setIsPaused(!isPaused);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: {
        personalQuizId: personalQuiz ? personalQuiz.id : "",
        secondsLeftAtPause: 10,
      },
      client: targetCustomer ? customerClient : guestClient,
    });

  const OS = getMobileOperatingSystem();

  const [triedOpeningInApp, setTriedOpeningInApp] = useState(false);

  useEffect(() => {
    if (!triedOpeningInApp && quiz && (OS === "Android" || OS === "iOS")) {
      const appLink = `tknight.live://battle/${quiz.id}`;

      const a = document.createElement("a");
      a.href = appLink;
      document.body.appendChild(a);
      setTriedOpeningInApp(true);

      a.click();
    }
  }, [triedOpeningInApp, quiz, OS]);

  return (
    <div className="w-screen overflow-hidden relative h-full min-h-screen">
      <audio ref={audioRef} src={backgroundMusic} loop volume={0.1} />

      <LiveStreamGradBack
        gradIndex={1}
        // gradIndex={targetCustomer ? targetCustomer.accentColor : 1}
      />

      {shouldShowMoreBattleOptions && quiz && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          topic={mainTopic}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMoreBattleOptions}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMoreBattleOptions}
          logout={logout}
          quiz={quiz}
          page="Battle"
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          shouldShowMoreBattleOptions={shouldShowMoreBattleOptions}
          setShouldShowMoreBattleOptions={setShouldShowMoreBattleOptions}
          shouldOpenQRCodeBox={shouldOpenQRCodeBox}
          setShouldOpenQRCodeBox={setShouldOpenQRCodeBox}
          toggleShowQRCode={toggleShowQRCode}
          toggleLeaderboardPopup={toggleLeaderboardPopup}
        />
      )}

      {shouldShowBattleLeaderboard &&
        quiz &&
        allPlayerBattleStats &&
        allPlayerBattleStats.length > 0 && (
          <BattleLeaderboard
            customer={customer}
            quiz={quiz}
            playerTopicStats={playerTopicStats}
            bailOut={bailOutByQuiz}
            logTriviaShare={logTriviaShare}
            allPlayerBattleStats={allPlayerBattleStats}
            isSharingInviteToApps={isSharingInviteToApps}
            isSharingToApps={isSharingToApps || isSharingInviteToApps}
            isShareCopied={isCaptionCopied || isInviteCopied}
            setIsSharingToApps={setIsSharingToApps}
            navigate={navigate}
            isInviteCopied={isInviteCopied}
            tnResultsFormatted={tnResultsFormatted}
            inviteFriends={inviteFriends}
            shouldShowBattleLeaderboard={shouldShowBattleLeaderboard}
            isFirstTime={isFirstTime}
            setIsFirstTime={setIsFirstTime}
            setShouldShowBattleLeaderboard={setShouldShowBattleLeaderboard}
            logout={logout}
            triviaWin={quizWin}
            guest={guest}
            targetGuestCustomer={targetGuestCustomer}
            targetCustomer={targetCustomer}
          />
        )}

      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      {powerUpToPurchase !== null && stripe && elements ? (
        <PurchasePowerUpForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          powerUpToPurchase={powerUpToPurchase}
          quizId={quizId || null}
          questionId={curQuestion ? curQuestion.id : null}
          togglePurchasePowerUpForm={togglePurchasePowerUpForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={quizId || null}
          questionId={curQuestion ? curQuestion.id : null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          isHigher={true}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && targetCustomer && stripe && elements ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      ) : (
        <></>
      )}
      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {targetTopic ? (
        <CreateBattleModal
          topic={targetTopic}
          navigate={navigate}
          shouldOpenQuiz={shouldOpenQuiz}
          setShouldOpenQuiz={setShouldOpenQuiz}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          targetCustomer={targetCustomer}
          targetGuest={targetGuestCustomer}
          lastQuizId={quizId || null}
          loginGuestContext={loginGuestContext}
          subPopupReason={subPopupReason}
          setSubPopupReason={setSubPopupReason}
          toggleOneTopicBattleForm={toggleOneTopicBattleForm}
        />
      ) : (
        <></>
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}

      {quiz ? (
        <div className="grow-0 h-screen z-10 flex flex-col items-center justify-center max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:px-0 px-4 short:py-2 py-4 sm:py-6 2xl:py-12">
          <div className="space-x-2 sm:space-x-4 relative z-20 px-2 sm:px-5 text-white flex items-center justify-between w-full">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              className="transition-opacity hover:opacity-50 py-1 focus:outline-none"
            >
              <svg
                className="h-5 fill-current "
                viewBox="0 0 140 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M69.7018 0C51.1365 0 33.3636 7.37493 20.2921 20.5025C7.22057 33.63 -0.0786027 51.4347 0.000638512 69.9999C0.0798797 88.5647 7.531 106.37 20.7136 119.497C33.8971 132.625 51.7337 140 70.2982 140C61.7401 140 53.3626 137.462 46.226 132.707C39.0902 127.952 33.5148 121.194 30.2062 113.287C26.8969 105.38 26.0032 96.679 27.637 88.2849C29.2709 79.8908 33.3595 72.1805 39.3851 66.1287C45.4116 60.077 53.1045 55.9556 61.491 54.286C69.8783 52.6163 78.5826 53.4732 86.5034 56.7484C94.4243 60.0236 101.206 65.57 105.991 72.6861C110.777 79.8019 113.35 88.1688 113.386 96.7275C113.435 108.204 108.923 119.21 100.842 127.325C92.7618 135.44 81.7751 140 70.2982 140C88.8635 140 106.636 132.625 119.708 119.497C132.779 106.37 140.079 88.5647 139.999 69.9999C139.92 51.4347 132.469 33.63 119.286 20.5025C106.103 7.37493 88.2671 0 69.7018 0Z" />
              </svg>
            </button>

            <div className="flex space-x-4 items-center justify-end w-full flex-1">
              {targetCustomer && !targetCustomer.isPaidSubscriber && (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    toggleBuySubscriptionForm(0);
                  }}
                  className="text-lg 2xl:text-2xl text-white hover:text-white/50 overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-full"
                >
                  <p className="font-semibold whitespace-nowrap drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
                    Unlock TKnight Pro
                  </p>
                </button>
              )}

              <PlayerCoinCounter
                targetCustomer={targetCustomer}
                targetGuestCustomer={targetGuestCustomer}
                loading={
                  loadingLivesCount ||
                  loadingGuestCustomer ||
                  loadingCustomerInfo
                }
                toggleBuyCoinsForm={toggleBuyCoinsForm}
              />
            </div>

            <div className="flex items-center justify-end">
              {customer ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsFirstTime(false);
                    setShouldShowMoreBattleOptions(true);
                  }}
                  className={`${
                    targetCustomer
                      ? targetCustomer.username === "humpty"
                        ? "bg-blue-300"
                        : // : targetCustomer.subscriptionType === 0
                          // ? "bg-yellow-300"
                          // : targetCustomer.subscriptionType === 1
                          // ? "bg-red-400"
                          "bg-black"
                      : "bg-black"
                  } hover:bg-opacity-50 group flex-shrink-0 transition-all overflow-hidden p-[.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 flex items-center justify-center 2xl:w-14`}
                >
                  <img
                    src={
                      targetCustomer
                        ? targetCustomer.profilePicUrl
                        : defaultPicUrl
                    }
                    alt={targetCustomer ? targetCustomer.username : "Knight"}
                    className="border-white group-hover:opacity-50 transition-all bg-black short:border-[1.5px] border-[1.75px] 2xl:border-4 box-border rounded-full object-cover aspect-square"
                  />
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    navigate("/register");
                  }}
                  className="text-sm  h-[1.9rem] sm:text-base group flex-shrink-0 flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white bg-opacity-20 rounded-full backdrop-blur-sm pl-4 pr-3 py-1 sm:h-full"
                >
                  <p className="font-bold">Sign in</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>

          {size.width < 1024 && (
            <div
              onClick={(e) => {
                e.preventDefault();
                if (!shouldOpenQuiz) {
                  setShouldOpenQRCodeBox(false);
                }
                setShouldOpenQuiz(!shouldOpenQuiz);
              }}
              className="text-left hide-button-flash cursor-pointer z-10 transition-all flex items-center justify-start lg:hidden hover:bg-gray-200 shadow-lg rounded-full mt-4 short:mt-3 border-4 border-white w-full bg-gray-100"
            >
              <PieTimer
                endTime={
                  (personalQuiz || quiz).status === 0
                    ? (personalQuiz || quiz).releaseDate
                    : (personalQuiz || quiz).status === 1
                    ? (personalQuiz || quiz).nextQStartsAt
                    : (personalQuiz || quiz).status === 2
                    ? (personalQuiz || quiz).curQEndsAt
                    : (personalQuiz || quiz).status === 3
                    ? (personalQuiz || quiz).curAnsEndsAt
                    : (personalQuiz || quiz).curQEndsAt
                }
                shouldPlaySoundEffects={
                  false // shouldOpenQuiz ? shouldPlaySoundEffects : false
                }
                imgUrl={
                  curQuestion &&
                  (personalQuiz || quiz).status !== 5 &&
                  (personalQuiz || quiz).status !== 0
                    ? curQuestion.creatorProfilePicUrl
                    : defaultPicUrl
                }
                alt={
                  quiz.visibilityType === 1
                    ? "Hosted by " + quiz.hostName
                    : targetCustomer
                    ? targetCustomer.name
                    : targetGuestCustomer
                    ? "Guest " + targetGuestCustomer.id
                    : quiz.hostName
                }
                width={size.height < 672 ? 30 : 40}
                lastXSeconds={(personalQuiz || quiz).nextQLastsXSeconds * 1000}
                quiz={personalQuiz || quiz}
              />
              <p className="sm:text-xl text-center w-full truncate pr-8 font-semibold">
                {quizId && !quiz
                  ? "Battle"
                  : (personalQuiz || quiz) &&
                    (personalQuiz || quiz).status === 0
                  ? (quiz.gameTimingType === 0 ? "Live " : "Async ") +
                    (quiz?.topicNames[0] || "") +
                    " Battle"
                  : (personalQuiz || quiz).status === 1
                  ? (personalQuiz || quiz).lastCompletedQIndex ===
                    (personalQuiz || quiz).numOfQs - 2
                    ? "Last Question"
                    : "Starting Question " +
                      ((personalQuiz || quiz).lastCompletedQIndex !== null
                        ? (personalQuiz || quiz).lastCompletedQIndex + 2
                        : 1)
                  : (personalQuiz || quiz).status === 2
                  ? "Question " +
                    ((personalQuiz || quiz).lastCompletedQIndex !== null
                      ? (personalQuiz || quiz).lastCompletedQIndex + 2
                      : 1)
                  : (personalQuiz || quiz).status === 3 ||
                    (personalQuiz || quiz).status === 4
                  ? "Question " +
                    ((personalQuiz || quiz).lastCompletedQIndex !== null
                      ? (personalQuiz || quiz).lastCompletedQIndex + 2
                      : 1) +
                    " Answer"
                  : quizWin
                  ? `You won!`
                  : quiz
                  ? "View Battle Result"
                  : `View TKnight Result`}
              </p>
            </div>
          )}

          <div className="flex w-full overflow-hidden pt-2 lg:py-6 lg:space-x-4 items-start justify-center flex-1">
            <TKLPopup
              toggleQuizForm={() => {
                if (!shouldOpenQuiz) {
                  setShouldOpenQRCodeBox(false);
                }
                setShouldOpenQuiz(!shouldOpenQuiz);
              }}
              targetPlayer={targetCustomer || targetGuestCustomer}
              client={targetCustomer ? customerClient : guestClient}
              isPlayingCheer={isPlayingCheer}
              quizId={quizId}
              personalQuiz={personalQuiz}
              refetchNumOfExtraLivesNeeded={refetchNumOfExtraLivesNeeded}
              numOfExtraLivesNeeded={numOfExtraLivesNeeded}
              loadingNumOfExtraLivesNeeded={loadingNumOfExtraLivesNeeded}
              setHasPlayedConfettiSound={setHasPlayedConfettiSound}
              hasPlayedConfettiSound={hasPlayedConfettiSound}
              shouldPlaySoundEffects={shouldPlaySoundEffects}
              selectedAnswer={selectedAnswer}
              refetchCustTriviaView={refetchCustTriviaView}
              playerBattleTopicStatsByBattleAndTopic={
                playerBattleTopicStatsByBattleAndTopic
              }
              custTriviaView={custTriviaView}
              toggleLeaderboardPopup={toggleLeaderboardPopup}
              refetchNarrowHorAd={refetchNarrowHorAd}
              playerTopicStats={playerTopicStats}
              logTriviaShare={logTriviaShare}
              loadingLogTriviaShare={loadingLogTriviaShare}
              refetchTNResultsFormatted={refetchTNResultsFormatted}
              isOutOfLives={isOutOfLives}
              loadingIsOutOfLives={loadingIsOutOfLives}
              refetchIsOutOfLives={refetchIsOutOfLives}
              isRobotPlaying={isRobotPlaying}
              shouldOpenQuiz={shouldOpenQuiz}
              refetchCustomer={refetchCustomer}
              refetchProductAds={refetchProductAds}
              refetchCurQ={refetchCurQ}
              quiz={quiz}
              loadingPersonalQuiz={loadingPersonalQuiz}
              loadingQuiz={loadingQuiz}
              triviaWin={quizWin}
              refetchPlayerBattleTopicStatsByBattleAndTopic={
                refetchPlayerBattleTopicStatsByBattleAndTopic
              }
              refetchAllPlayerBattleStatsByBattle={
                refetchAllPlayerBattleStatsByBattle
              }
              tnResultsFormatted={tnResultsFormatted}
              targetCustomer={targetCustomer}
              targetGuestCustomer={targetGuestCustomer}
              quizWin={quizWin}
              livesCount={livesCount}
              loadingLivesCount={loadingLivesCount}
              loadingQuizWin={loadingQuizWin}
              curQuestion={curQuestion}
              loadingCustTriviaView={loadingCustTriviaView}
              loadingSelectedAnswerStats={loadingSelectedAnswerStats}
              selectedAnswerStats={selectedAnswerStats}
              refetchSelectedAnswerStats={refetchSelectedAnswerStats}
              refetchLivesCount={refetchLivesCount}
              refetchTriviaWin={refetchQuizWinByCustomer}
              toggleBuySubscriptionForm={toggleBuySubscriptionForm}
              togglePurchasePowerUpForm={togglePurchasePowerUpForm}
              productAds={productAds}
              size={size}
              setTargetTopic={setTargetTopic}
              createGuestCustomerErr={errors.email}
            />

            <CommentSection
              toggleQuizForm={() => {
                if (!shouldOpenQuiz) {
                  setShouldOpenQRCodeBox(false);
                }
                setShouldOpenQuiz(!shouldOpenQuiz);
              }}
              toggleShowQRCode={toggleShowQRCode}
              quizId={quizId}
              isOutOfLives={isOutOfLives}
              targetAdBookmark={
                // size.width >= 640 &&
                // (personalQuiz || quiz) &&
                // (personalQuiz || quiz).status === 5
                //   ? regularHorAdBookmark
                //   :

                !shouldOpenQuiz ? regularHorAdBookmark : narrowAdBookmark
              }
              regularHorAd={regularHorAd}
              narrowHorAd={narrowHorAd}
              narrowAdBookmark={narrowAdBookmark}
              areCommentsInFocus={size.width >= 1024 ? true : !shouldOpenQuiz}
              targetCustomer={targetCustomer}
              targetGuestCustomer={targetGuestCustomer}
              productAds={productAds}
              quiz={quiz}
              personalQuiz={personalQuiz}
              country={values.country}
              navigate={navigate}
              size={size}
              livesCount={livesCount}
              toggleBuySubscriptionForm={toggleBuySubscriptionForm}
              togglePurchasePowerUpForm={togglePurchasePowerUpForm}
              shouldOpenQRCodeBox={shouldOpenQRCodeBox}
              setShouldOpenQRCodeBox={setShouldOpenQRCodeBox}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {((size.width >= 1024 && regularHorAd) ||
        (size.width < 1024 && squareAd)) &&
        quiz &&
        ((quiz.gameTimingType !== 0 && personalQuiz) ||
          quiz.gameTimingType === 0) &&
        shouldOpenAdPopup && (
          <HorAdOverlayBox
            targetCustomer={targetCustomer}
            quiz={quiz}
            personalQuiz={personalQuiz}
            targetAdBookmark={
              size.width >= 1024 ? regularHorAdBookmark : squareAdBookmark
            }
            loading={loadingTogglePausePersonalQuiz || loadingTogglePauseQuiz}
            togglePausePersonalQuiz={togglePausePersonalQuiz}
            togglePauseQuiz={togglePauseQuiz}
            toggleAdPopup={toggleAdPopup}
            overlayAd={size.width >= 1024 ? regularHorAd : squareAd}
            shouldOpenAdPopup={shouldOpenAdPopup}
            values={values}
            isOutOfLives={isOutOfLives}
            endTime={(personalQuiz || quiz).nextQStartsAt}
            tempElapsedTime={tempElapsedTime}
            setTempElapsedTime={setTempElapsedTime}
            imgUrl={
              (size.width >= 1024 ? regularHorAd : squareAd).businessLogoUrl ||
              defaultPicUrl
            }
            isPaused={isPaused}
            setIsPaused={setIsPaused}
            alt={
              quiz.visibilityType === 1
                ? "Hosted by " + quiz.hostName
                : targetCustomer
                ? targetCustomer.name
                : targetGuestCustomer
                ? "Guest " + targetGuestCustomer.id
                : quiz.hostName
            }
            width={size.width > 1536 ? 250 : 200}
            lastXSeconds={(personalQuiz || quiz).nextQLastsXSeconds * 1000}
          />
        )}
    </div>
  );
}

export default Battle;
