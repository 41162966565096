import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import Confetti from "react-confetti";
import { useNavigate, useParams } from "react-router";

import { useQuery } from "@apollo/client";
import { customerClient } from "../../GraphqlApolloClients";
import LiveStreamGradBack from "../../components/creator/LiveStreamGradBack";
import { CustomerAuthContext } from "../../context/customerAuth";
import { useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import MiniNavBar from "../../components/creator/MiniNavBar";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import TopBar from "../../components/creator/TopBar";

import PlayerSummaryBox from "../../components/creator/PlayerSummaryBox";
import {
  FETCH_CUSTOMER,
  FETCH_ORG_BY_ID,
  FETCH_QUIZ_BY_ID,
  FETCH_RECOMMENDED_FRIENDS,
  FETCH_TOPIC,
} from "../../util/gqlFunctions";

function AddFriends(props) {
  usePageTracking();
  const { topicId, quizId, orgId } = useParams();

  let navigate = useNavigate();

  const {
    customer,
    logoutCustomer: logout,
    friendIds,
  } = useContext(CustomerAuthContext);
  useEffect(() => {
    if (!customer) {
      navigate("/login/redirect/addFriends");
    }
  }, [customer, navigate]);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    document.title = "Add Friends | TKnight";
  }, []);
  const size = useWindowSize();

  const { values, setValues } = useForm(editCustomerCallback, {
    searchTerm: "",
    friendId: null,
    pageSize: 20,
    seenIds: [],
    emails: [],
    phoneNumbers: [],
    selectedFriendIds:
      topicId && friendIds && friendIds.length > 0 ? friendIds : [],
    orgId,
  });

  const {
    data: { getQuizById: quiz } = {},
    // loading: loadingTriviaNight,
  } = useQuery(FETCH_QUIZ_BY_ID, {
    variables: { quizId: quizId },
    onCompleted: (data) => {
      setValues({ ...values, selectedFriendIds: data.friendIds });
    },
  });

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  useEffect(() => {
    if (
      targetCustomer &&
      (!targetCustomer.curRegistrationStep ||
        targetCustomer.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + targetCustomer.curRegistrationStep);
    }
  }, [targetCustomer, navigate]);

  const {
    data: { getRecommendedFriends: recommendedFriends } = {},

    refetch: refetchRecommendedFriends,
  } = useQuery(FETCH_RECOMMENDED_FRIENDS, {
    client: customerClient,
    variables: values,
  });

  function editCustomerCallback(e) {
    e.preventDefault();
  }

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && size.width >= 1024) {
      inputRef.current.focus();
    }
  }, [size.width]);

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out tknight.live\n\n";
    textToShare +=
      "If you survive 7 trivia questions, you get to split the prize with other winners. ";

    textToShare += "It's really fun!!\n\n";
    if (targetCustomer) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + targetCustomer.username + '"\n\n';
    }
    textToShare += "#TKnight";

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on TKnight!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const friendRef = useRef();

  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && targetCustomer) {
      setShouldPlaySoundEffects(
        targetCustomer.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, targetCustomer]);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);

  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);

  const toggleBuySubscriptionForm = (subscriptionType) => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setSubscriptionType(subscriptionType);

    if (subscriptionType === null) {
      setShouldOpenReviewForm(true);
    } else {
      setShouldOpenReviewForm(false);
    }
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };
  const { data: { getOrgById: targetOrg } = {} } = useQuery(FETCH_ORG_BY_ID, {
    variables: { orgId: orgId || "" },
  });

  const { data: { getTopicById: targetTopic } = {} } = useQuery(FETCH_TOPIC, {
    variables: { topicId: topicId || quiz?.topicIds[0] || "" },
  });

  return (
    <div className="w-screen overflow-x-hidden h-full max-h-screen ">
      <LiveStreamGradBack
        gradIndex={
          targetCustomer && targetCustomer.accentColor
            ? targetCustomer.accentColor
            : 1
        }
      />
      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={quizId || null}
          // questionId={curQuestion ? curQuestion.id : null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          // subPopupReason={""}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="TKnight Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.subscriptionType === 0
                ? "Individual"
                : targetCustomer.subscriptionType === 1
                ? "Duo"
                : targetCustomer.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to TKnight Pro, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      <div
        className={`relative z-10 overflow-hidden flex flex-col h-screen max-h-screen short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 lg:pb-8`}
      >
        <TopBar
          topicShortForm={targetTopic?.shortForm || null}
          topicId={targetTopic?.id || null}
          topicName={targetTopic ? targetTopic.name + " Battle" : null}
          topicLogoUrl={targetTopic?.logoUrl || null}
          orgLogoUrl={targetOrg?.logoUrl || null}
          orgNameForUrl={targetOrg?.name || null}
          orgShortForm={targetOrg?.shortForm || null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={null}
          targetCustomer={targetCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={false}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Friends"
        />

        <div
          className={`bg-white space-x-2 short:space-x-1.5 rounded-lg text-black duration-700 transition-height px-3 short:pl-2 short:pr-2 sm:px-4 flex items-center justify-start border w-full`}
        >
          {
            <svg
              className="fill-current w-5 short:w-4"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
              />
            </svg>
          }

          <input
            ref={inputRef}
            name={"searchTerm"}
            id={"searchTerm"}
            value={values.searchTerm}
            onChange={(e) => {
              setValues({ ...values, searchTerm: e.target.value });

              refetchRecommendedFriends({
                searchTerm: e.target.value,
                pageSize: 20,
                seenIds: [],
              });
            }}
            className={`sm:text-lg text-base xshort:text-xs short:py-2 py-3 font-bold leading-none w-full flex-1 bg-transparent placeholder:text-gray-400 focus:outline-none`}
            placeholder={orgId ? "Search members" : "Search players"}
            // disabled={loadingCreateComment}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" && e.shiftKey === false) {
            //     e.preventDefault();
            //     createComment();
            //   }
            // }}
            maxLength={100}
          />
        </div>

        <button
          onClick={inviteFriends}
          className={`w-full mb-2 flex rounded-md text-sm sm:text-base ${
            isSharingInviteToApps || isInviteCopied
              ? "bg-green-200 hover:bg-green-400"
              : "bg-gray-100 hover:bg-opacity-90"
          } transition-all py-1.5 items-center justify-center space-x-1.5 mt-2`}
        >
          <p className=" leading-tight">
            {isSharingInviteToApps
              ? "Invite shared!"
              : isInviteCopied
              ? "Invite copied!"
              : "Invite for extra life"}
          </p>
          <svg
            className="h-3 sm:h-4 aspect-square fill-current "
            viewBox="0 0 21 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_8661_576)">
              <path d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"></path>
            </g>
          </svg>
        </button>

        <div className="relative w-full flex-1 flex flex-row gap-6 rounded-lg sm:rounded-none sm:gap-10 snap-x snap-mandatory overflow-hidden my-2 sm:my-0">
          <div
            className={`snap-center flex sm:py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={friendRef}
          >
            {(quiz || topicId) && targetCustomer ? (
              <PlayerSummaryBox
                key={targetCustomer.id}
                player={targetCustomer}
                targetCustomer={targetCustomer}
                isForBattle={quiz ? true : false}
                isForCreateBattle={!quiz && topicId ? true : false}
                targetBattle={quiz}
              />
            ) : null}

            {targetCustomer &&
            recommendedFriends &&
            recommendedFriends.length > 0 ? (
              recommendedFriends.map((player) => (
                <PlayerSummaryBox
                  key={player.id}
                  player={player}
                  targetCustomer={targetCustomer}
                  isForBattle={quiz ? true : false}
                  isForCreateBattle={!quiz && topicId ? true : false}
                  targetBattle={quiz}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* 
        <button
          disabled={!targetCustomer}
          onClick={(e) => {
            if (topicId || quizId || orgId) {
              navigate(-1);
            } else {
              navigate("/");
            }
          }}
          className="w-full p-3 uppercase  py-4 short:py-2 disabled:opacity-50 hover:bg-opacity-70 transition-all rounded-full font-bold bg-white text-black  text-2xl short:text-lg  sm:text-2xl"
        >
          Done
        </button> */}
      </div>
    </div>
  );
}

export default AddFriends;
