import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useNavigate, useParams } from "react-router";

import { useMutation, useQuery } from "@apollo/client";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import Confetti from "react-confetti";
import { customerClient } from "../../GraphqlApolloClients";
import BlueAngledGradBack from "../../components/creator/BlueAngledGradBack";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuySubscriptionForm from "../../components/creator/BuySubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";
import CustomerSubscriptionPaymentSuccessfulForm from "../../components/creator/CustomerSubscriptionPaymentSuccessfulForm";
import DownloadAppPopup from "../../components/creator/DownloadAppPopup";
import EditProForm from "../../components/creator/EditProForm";
import EditProMembersForm from "../../components/creator/EditProMembersForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import LoginFooter from "../../components/creator/LoginFooter";
import MiniNavBar from "../../components/creator/MiniNavBar";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import { CustomerAuthContext } from "../../context/customerAuth";
import {
  EDIT_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUBSCRIPTION,
  FETCH_FRIENDS_BY_SUBSCRIPTION,
} from "../../util/gqlFunctions";
import { useForm, useWindowSize } from "../../util/hooks";
const appVersion = "0.2.1";

function CustomerAccountSettings(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);

  const { shouldUnsubscribe } = useParams();

  useEffect(() => {
    if (!customer) {
      if (shouldUnsubscribe) {
        navigate("/login/redirect/settings>" + shouldUnsubscribe);
      } else {
        navigate("/login");
      }
    }
  }, [customer, navigate, shouldUnsubscribe]);

  useEffect(() => {
    document.title = "Settings | TKnight";
  }, []);

  const [valsAreInitialized, setValsAreInitialized] = useState(false);

  const { values, setValues } = useForm(editCustomerCallback, {
    name: "",
    username: "",
    profilePicUrl: "https://tknight-media.s3.amazonaws.com/profile.png",
    instaHandle: "",
    twitterHandle: "",
    tiktokHandle: "",
    redditHandle: "",
    email: "",
    phone: "",
    password: "",
    city: "",
    country: "",
    remindersToReceive: [0, 1, 2],
    fieldsToEdit: [],
    oldPassword: "",
    birthday: null,
    gender: null,
    displayCity: "",
    inviteCode: "",
  });

  const {
    data: { getCustomer: customerInfo } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  useEffect(() => {
    if (
      customerInfo &&
      (!customerInfo.curRegistrationStep ||
        customerInfo.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + customerInfo.curRegistrationStep);
    }
  }, [customerInfo, navigate]);

  const {
    data: { getFriendsBySubscription: friendsWithSameSubscription } = {},
    // loading: loadingFriendsWithSameSub,
  } = useQuery(FETCH_FRIENDS_BY_SUBSCRIPTION, {
    client: customerClient,
  });

  const {
    data: { getCustomerSubscription: customerSubscription } = {},
    loading: loadingCustomerSubscription,
  } = useQuery(FETCH_CUSTOMER_SUBSCRIPTION, {
    client: customerClient,
    variables: {
      status: 1,
    },
  });

  const [editCustomer, { loading: loadingEditCustomer }] = useMutation(
    EDIT_CUSTOMER,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER }],
      update(_, { data: { editCustomer: customerData } }) {
        setValues({
          ...values,
          name: values?.fieldsToEdit.includes("name")
            ? customerData.name
            : values.name,

          email: values?.fieldsToEdit.includes("email")
            ? customerData.email
            : values.email,
          phone: values?.fieldsToEdit.includes("phone")
            ? customerData.phone
            : values.phone,
          cityId: customerData.cityId || "",
          city: customerData.city || "",
          country: customerData.country || "",
          fieldsToEdit: [],
          oldPassword: "",
          password: "",
          username: values?.fieldsToEdit.includes("username")
            ? customerData.username
            : values.username,
          profilePicUrl: customerData.profilePicUrl,
          accentColor: customerData.accentColor || 0,
          instaHandle: customerData.instaHandle || "",
          redditHandle: customerData.redditHandle || "",
          twitterHandle: customerData.twitterHandle || "",
          tiktokHandle: customerData.tiktokHandle || "",
          remindersToReceive: customerData.remindersToReceive,
          birthday: customerData.birthday || null,
          gender: customerData.gender,
        });
      },
      onError(err) {
        console.log(err);
        console.log(values);
      },
      variables: values,
      client: customerClient,
    }
  );

  const stripe = useStripe();
  const elements = useElements();

  function editCustomerCallback(e) {
    e.preventDefault();
    editCustomer();
  }

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !customerInfo &&
        !checkedIsCustomerLoggedIn) ||
      (customerInfo && customerInfo.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    customerInfo,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  useEffect(() => {
    if (
      !valsAreInitialized &&
      customerInfo &&
      !loadingEditCustomer &&
      !loadingCustomerInfo
    ) {
      setValues({
        ...values,
        name: customerInfo.name,
        email: customerInfo.email,
        phone: customerInfo.phone,
        city: customerInfo.city || "",
        country: customerInfo.country || "",
        oldPassword: "",
        username: customerInfo.username,
        accentColor: customerInfo.accentColor,
        profilePicUrl: customerInfo.profilePicUrl,
        profilePicFile: null,
        instaHandle: customerInfo.instaHandle || "",
        redditHandle: customerInfo.redditHandle || "",
        twitterHandle: customerInfo.twitterHandle || "",
        tiktokHandle: customerInfo.tiktokHandle || "",
        remindersToReceive: customerInfo.remindersToReceive,
        birthday: customerInfo.birthday || null,
        gender: customerInfo.gender,
        subscriptionType: customerInfo.subscriptionType,
      });

      setValsAreInitialized(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    values,
    setValues,
    customerInfo,
    loadingEditCustomer,
    valsAreInitialized,
  ]);

  const [isUnsubscribedChecked, setIsUnsubscribedChecked] = useState(false);

  useEffect(() => {
    if (
      shouldUnsubscribe === "unsubscribe" &&
      valsAreInitialized &&
      !isUnsubscribedChecked
    ) {
      editCustomer({
        variables: {
          fieldsToEdit: ["remindersToReceive"],
          remindersToReceive: [],
        },
      });
      setValues({
        ...values,
        fieldsToEdit: ["remindersToReceive"],
        remindersToReceive: [],
      });

      setIsUnsubscribedChecked(true);
      setIsUnsubscribePopupOpen(true);
    }
  }, [
    editCustomer,
    shouldUnsubscribe,
    valsAreInitialized,
    values,
    setValues,
    isUnsubscribedChecked,
  ]);

  const [subscriptionType, setSubscriptionType] = useState(null);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);
  // console.log(personalQuiz ? "PQ id: " + personalQuiz.id : "");

  // const [isSharingToApps, setIsSharingToApps] = useState(false);
  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Play TKnight!\n\n";
    textToShare +=
      "Learn more about your favourite topics and battle with trivia players around the world\n";

    textToShare += "It's really fun!!\n\n";
    if (customerInfo) {
      textToShare +=
        'Sign up with my extra ❤️ code: "' + customerInfo.username + '"\n\n';
    }
    textToShare += "#TKnight";

    if (navigator.share) {
      navigator
        .share({
          title: "Play TKnight!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  // const [isSharingInviteToApps, setIsSharingToApps] = useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);

  const shareCustSubInvite = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = null;

    textToShare =
      customerSubscription.subscriptionType === 1
        ? "Join my TKnight Pro duo with code: " + customerSubscription.id
        : "Join our TKnight Pro family with code: " + customerSubscription.id;

    if (navigator.share) {
      navigator
        .share({
          title: "Your TKnight Pro Invite",
          text: textToShare,
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  const [shouldOpenEditMembersForm, setShouldOpenEditMembersForm] =
    useState(false);

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldOpenDownloadAppPopup, setShouldOpenDownloadAppPopup] =
    useState(false);
  const toggleBuySubscriptionForm = (subscriptionType) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditProForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setSubscriptionType(subscriptionType);
    setShouldOpenChangePlanForm(!shouldOpenChangePlanForm);
  };
  const toggleEditProForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenChangePlanForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenEditProForm(!shouldOpenEditProForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };
  const toggleManageMembersForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenEditMembersForm(!shouldOpenEditMembersForm);
  };

  const toggleProInviteCodeForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleDownloadAppPopup = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenDownloadAppPopup(!shouldOpenDownloadAppPopup);
  };

  const toggleJoinedProPlanPopup = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleReviewForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  };
  const toggleBuyCoinsForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleCoinPurchaseSuccessfulForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const [isUnsubscribePopupOpen, setIsUnsubscribePopupOpen] = useState(false);

  const resetInterval = 3000;

  useEffect(() => {
    let timeout;
    if (isUnsubscribePopupOpen && resetInterval) {
      timeout = setTimeout(
        () => setIsUnsubscribePopupOpen(false),
        resetInterval
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isUnsubscribePopupOpen, resetInterval]);

  const { width, height } = useWindowSize();

  const [shouldOpenChangePlanForm, setShouldOpenChangePlanForm] =
    useState(false);
  const [shouldOpenEditProForm, setShouldOpenEditProForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);

  const toggleReviewSentForm = () => {
    setSubscriptionType(null);
    setShouldOpenEditProForm(false);
    setShouldOpenEditMembersForm(false);
    setShouldOpenProInviteCodeForm(false);
    setShouldOpenJoinedProPlanPopup(false);
    setShouldOpenReviewForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenDownloadAppPopup(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueAngledGradBack gradIndex={values.accentColor} />

      {shouldOpenReviewForm && customerInfo ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={customerInfo}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenDownloadAppPopup ? (
        <DownloadAppPopup toggleDownloadAppPopup={toggleDownloadAppPopup} />
      ) : (
        <></>
      )}

      {subscriptionType !== null &&
      shouldOpenChangePlanForm &&
      customerInfo &&
      stripe &&
      elements ? (
        <BuySubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={customerInfo}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          customerSubscription={customerSubscription}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      ) : (
        <></>
      )}

      {subscriptionType !== null && shouldOpenEditProForm && customerInfo && (
        <EditProForm
          targetCustomer={customerInfo}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          subscriptionType={subscriptionType}
          customerSubscription={customerSubscription}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleEditProForm={toggleEditProForm}
          toggleManageMembersForm={toggleManageMembersForm}
        />
      )}

      {customerSubscription && shouldOpenEditMembersForm && customerInfo && (
        <EditProMembersForm
          targetCustomer={customerInfo}
          subscriptionType={subscriptionType}
          customerSubscription={customerSubscription}
          members={friendsWithSameSubscription}
          toggleManageMembersForm={toggleManageMembersForm}
        />
      )}

      {shouldShowMiniNavBar && customerInfo && (
        <MiniNavBar
          targetCustomer={customerInfo}
          isSharingToApps={isSharingToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          hideAccountsLink={true}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}
      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={customerInfo}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          questionId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}
      {customerSubscription && shouldOpenJoinedProPlanPopup && customerInfo && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={customerInfo}
            message={"Welcome to TKnight Pro, " + customerInfo.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (customerSubscription.subscriptionType === 0
                ? "Individual"
                : customerSubscription.subscriptionType === 1
                ? "Duo"
                : customerSubscription.subscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenProInviteCodeForm && customerInfo && (
        <CodeForm
          title="TKnight Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenPaymentSuccessfulForm &&
        customerInfo &&
        customerSubscription && (
          <>
            <CustomerSubscriptionPaymentSuccessfulForm
              togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
              targetCustomer={customerInfo}
              message={"Welcome to TKnight Pro, " + customerInfo.name + "."}
              navigate={navigate}
              customerSubscription={customerSubscription}
            />
            <div className="absolute z-[90] pointer-events-none inset-0">
              <Confetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={width >= 640 ? 1500 : 1000}
                tweenDuration={30 * 1000}
                gravity={0.3}
              />
            </div>
          </>
        )}

      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 lg:pb-0">
        {isUnsubscribePopupOpen ? (
          <div className="fixed px-4 sm:px-0 left-0 w-full sm:max-w-xl sm:inset-x-auto top-5 sm:top-4 z-50">
            <div className="w-full motion-safe:animate-fadeDown font-semibold text-center text-lg sm:max-w-xl rounded-lg py-2 bg-red-600 text-white">
              Unsubscribed from TN reminder emails
            </div>
          </div>
        ) : (
          <></>
        )}

        <TopBar
          topicShortForm={null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetGuestCustomer={null}
          targetCustomer={customerInfo}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          areFriendRequestsAvailable={false}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Account"
          topicId={null}
        />

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Settings
          </p>

          <div className="w-full flex flex-col items-center justify-start space-y-6 mt-6 short:mt-4">
            <div className="w-full">
              <div className="flex items-center justify-between text-sm mb-1 sm:text-base">
                <label
                  htmlFor={"subscriptionType"}
                  className="font-semibold text-gray-700"
                >
                  TKnight Pro
                </label>
                {loadingCustomerInfo || loadingCustomerSubscription ? (
                  <></>
                ) : customerInfo && customerInfo.isPaidSubscriber ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSubscriptionType(customerInfo.subscriptionType);
                      if (customerInfo.subscriptionType === 0) {
                        toggleBuySubscriptionForm(
                          customerInfo.subscriptionType
                        );
                      } else {
                        toggleEditProForm();
                      }
                    }}
                    className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggleBuySubscriptionForm(0);
                    }}
                    className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                  >
                    Select a plan
                  </button>
                )}
              </div>
              {loadingCustomerInfo || loadingCustomerSubscription ? (
                <></>
              ) : customerInfo &&
                customerInfo.isPaidSubscriber &&
                customerSubscription ? (
                <div
                  className={`${
                    customerSubscription.subscriptionType === 0
                      ? "border-blue-500  bg-blue-500 text-gray-100"
                      : customerSubscription.subscriptionType === 1
                      ? "border-orange-500  bg-orange-500 text-gray-100"
                      : customerSubscription.subscriptionType === 2
                      ? "border-fuchsia-600  bg-fuchsia-600 text-gray-100"
                      : "border-lime-600  bg-lime-600 text-gray-100"
                  } transition-all relative text-left w-full shadow-sm flex-1 px-3 py-2 rounded-md border`}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className=" text-sm sm:text-base">
                      {customerSubscription.subscriptionType === 0
                        ? "Individual"
                        : customerSubscription.subscriptionType === 1
                        ? "Duo"
                        : customerSubscription.subscriptionType === 2
                        ? "Family"
                        : "Club"}{" "}
                      Plan
                    </p>
                    <svg
                      className={`${
                        customerSubscription.subscriptionType === 0
                          ? "opacity-100 stroke-blue-500 text-white "
                          : customerSubscription.subscriptionType === 1
                          ? "opacity-100 stroke-orange-500 text-white "
                          : customerSubscription.subscriptionType === 2
                          ? "opacity-100 stroke-fuchsia-600 text-white "
                          : "opacity-100 stroke-lime-600 text-white "
                      } transition-all h-4  fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" />
                    </svg>
                  </div>

                  {customerSubscription.subscriptionType !== 0 &&
                    friendsWithSameSubscription &&
                    friendsWithSameSubscription.length > 0 &&
                    customerInfo && (
                      <div className="flex items-center overflow-x-auto mt-2 mb-1 group justify-start pr-2">
                        <div
                          className={`${
                            customerInfo.username === "humpty"
                              ? "bg-blue-300"
                              : "bg-gray-100/50"
                          }  flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden rounded-full aspect-square w-10 2xl:w-14 short:w-8 flex`}
                        >
                          <img
                            src={customerInfo.profilePicUrl}
                            alt={customerInfo.username}
                            className="border-white bg-white border-[3px] 2xl:border-4 box-border rounded-full object-cover aspect-square"
                          />
                        </div>

                        {friendsWithSameSubscription
                          .filter((friend) => friend.id !== customerInfo.id)
                          .map((friend, index) => (
                            <div
                              className={`${
                                friend.username === "humpty"
                                  ? "bg-blue-300"
                                  : "bg-gray-100/50"
                              } -ml-5 sm:group-hover:ml-0 flex-shrink-0 mr-2 2xl:mr-4 transition-all overflow-hidden rounded-full aspect-square w-10 2xl:w-14 short:w-8 flex`}
                            >
                              <img
                                src={friend.profilePicUrl}
                                alt={friend.username}
                                className="border-white bg-white border-[3px] 2xl:border-4 box-border rounded-full object-cover aspect-square"
                              />
                            </div>
                          ))}
                      </div>
                    )}

                  {customerSubscription.subscriptionType !== 0 &&
                    customerSubscription.numOfMembers <
                      customerSubscription.maxNumOfMembers && (
                      <>
                        <button
                          onClick={(e) => {
                            shareCustSubInvite(e);
                          }}
                          type="button"
                          className={`${
                            isCaptionCopied ? " motion-safe:animate-popUp " : ""
                          } text-black my-2 relative text-xl 2xl:text-2xl hover:bg-gray-200 overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-white rounded-full pl-4 pr-3 py-1`}
                        >
                          <p
                            className={`${
                              isCaptionCopied
                                ? "motion-safe:animate-fadeDownFast"
                                : hasCaptionBeenCopiedBefore
                                ? "motion-safe:animate-fadeOutUpFast"
                                : "hidden"
                            } bg-black pb-2 text-white inset-0 pt-2.5 w-full absolute`}
                          >
                            {isSharingToApps ? "Shared!" : "Copied"}
                          </p>

                          <p
                            className={`${
                              isCaptionCopied
                                ? "motion-safe:animate-fadeOutDownFast"
                                : hasCaptionBeenCopiedBefore
                                ? "motion-safe:animate-fadeUpFast"
                                : ""
                            } py-2`}
                          >
                            Share Pro Code
                          </p>
                        </button>
                        {customerSubscription.subscriptionType !== 0 && (
                          <p className=" text-white text-center">
                            {customerSubscription.numOfMembers}/
                            {customerSubscription.maxNumOfMembers} Pro accounts
                            are being used
                          </p>
                        )}
                      </>
                    )}
                </div>
              ) : (
                customerInfo && (
                  <div className="w-full">
                    <div className="grid grid-cols-2 gap-2 w-full">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBuySubscriptionForm(0);
                        }}
                        className={`${
                          customerInfo.isPaidSubscriber &&
                          customerInfo.subscriptionType === 0
                            ? "border-blue-500  bg-blue-500 text-gray-200"
                            : "border-gray-200 hover:bg-gray-50 text-gray-400"
                        } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                      >
                        <div className="w-full flex items-center justify-between">
                          <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                            Individual
                          </p>
                          <svg
                            className={`${
                              customerInfo.isPaidSubscriber &&
                              customerInfo.subscriptionType === 0
                                ? "opacity-100 stroke-blue-500 text-white "
                                : "text-gray-200 opacity-0 "
                            } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="6.5" cy="6.5" r="6.5" />
                            <path d="M3 6.72727L5.5 9L10 4" />
                          </svg>
                        </div>
                        <p
                          className={`text-xl short:text-lg short:leading-tight leading-tight ${
                            customerInfo.isPaidSubscriber &&
                            customerInfo.subscriptionType === 0
                              ? "text-white"
                              : "text-gray-800"
                          }`}
                        >
                          US$
                          {customerInfo.curSubscriptionPrice
                            ? (customerInfo.curSubscriptionPrice / 100).toFixed(
                                2
                              )
                            : "4.19"}
                          /mo
                        </p>
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBuySubscriptionForm(2);
                        }}
                        className={`${
                          customerInfo.isPaidSubscriber &&
                          customerInfo.subscriptionType === 2
                            ? "border-fuchsia-600  bg-fuchsia-600 text-gray-200"
                            : "border-gray-200 hover:bg-gray-50 text-gray-400"
                        } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                      >
                        <div className="w-full flex items-center justify-between">
                          <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                            Family
                          </p>
                          <svg
                            className={`${
                              customerInfo.isPaidSubscriber &&
                              customerInfo.subscriptionType === 2
                                ? "opacity-100 stroke-fuchsia-600 text-white "
                                : "text-gray-200 opacity-0 "
                            } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="6.5" cy="6.5" r="6.5" />
                            <path d="M3 6.72727L5.5 9L10 4" />
                          </svg>
                        </div>
                        <p
                          className={`text-xl short:text-lg short:leading-tight leading-tight ${
                            customerInfo.isPaidSubscriber &&
                            customerInfo.subscriptionType === 2
                              ? "text-white"
                              : "text-gray-800"
                          }`}
                        >
                          US$
                          {customerInfo.curSubscriptionPrice
                            ? (customerInfo.curSubscriptionPrice / 100).toFixed(
                                2
                              )
                            : "9.19"}
                          /mo
                        </p>
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBuySubscriptionForm(1);
                        }}
                        className={`${
                          customerInfo.isPaidSubscriber &&
                          customerInfo.subscriptionType === 1
                            ? "border-orange-500  bg-orange-500 text-gray-200"
                            : "border-gray-200 hover:bg-gray-50 text-gray-400"
                        } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                      >
                        <div className="w-full flex items-center justify-between">
                          <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                            Duo
                          </p>
                          <svg
                            className={`${
                              customerInfo.isPaidSubscriber &&
                              customerInfo.subscriptionType === 1
                                ? "opacity-100 stroke-orange-500 text-white "
                                : "text-gray-200 opacity-0 "
                            } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="6.5" cy="6.5" r="6.5" />
                            <path d="M3 6.72727L5.5 9L10 4" />
                          </svg>
                        </div>
                        <p
                          className={`text-xl short:text-lg short:leading-tight leading-tight ${
                            customerInfo.isPaidSubscriber &&
                            customerInfo.subscriptionType === 1
                              ? "text-white"
                              : "text-gray-800"
                          }`}
                        >
                          US$
                          {customerInfo.curSubscriptionPrice
                            ? (customerInfo.curSubscriptionPrice / 100).toFixed(
                                2
                              )
                            : "7.49"}
                          /mo
                        </p>
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBuySubscriptionForm(3);
                        }}
                        className={`${
                          customerInfo.isPaidSubscriber &&
                          customerInfo.subscriptionType === 3
                            ? "border-lime-600  bg-lime-600 text-gray-200"
                            : "border-gray-200 hover:bg-gray-50 text-gray-400"
                        } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                      >
                        <div className="w-full flex items-center justify-between">
                          <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                            Club
                          </p>
                          <svg
                            className={`${
                              customerInfo.isPaidSubscriber &&
                              customerInfo.subscriptionType === 3
                                ? "opacity-100 stroke-lime-600 text-white "
                                : "text-gray-200 opacity-0 "
                            } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="6.5" cy="6.5" r="6.5" />
                            <path d="M3 6.72727L5.5 9L10 4" />
                          </svg>
                        </div>
                        <p
                          className={`text-xl short:text-lg short:leading-tight leading-tight ${
                            customerInfo.isPaidSubscriber &&
                            customerInfo.subscriptionType === 3
                              ? "text-white"
                              : "text-gray-800"
                          }`}
                        >
                          US$
                          {customerInfo.curSubscriptionPrice
                            ? (customerInfo.curSubscriptionPrice / 100).toFixed(
                                2
                              )
                            : "99"}
                          /mo
                        </p>
                      </button>
                    </div>
                    {customerInfo.isPaidSubscriber ? (
                      <></>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          toggleProInviteCodeForm();
                        }}
                        className={`w-full flex space-x-3 items-center justify-between border-gray-200 hover:bg-gray-50 text-gray-400 transition-all mt-2 group relative text-left shadow-sm flex-1 short:px-3 px-4 py-2 rounded-md border`}
                        disabled={loadingCustomerInfo}
                      >
                        <svg
                          className="aspect-square w-4 fill-current text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-1.293l7.06-7.06c-.214-.26-.413-.533-.599-.815l-6.461 6.461v-2.293l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z" />
                        </svg>
                        <p className="font-semibold flex-1 text-left text-gray-500">
                          Have a Pro Code?
                        </p>
                        <svg
                          className="aspect-square w-3 fill-current text-gray-400"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                        </svg>
                      </button>
                    )}
                  </div>
                )
              )}
            </div>

            <div className="w-full">
              <div className=" w-full flex items-center justify-between text-sm mb-1 sm:text-base">
                <label
                  htmlFor={"subscriptionType"}
                  className="font-semibold text-gray-700"
                >
                  Account
                </label>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  toggleDownloadAppPopup();
                }}
                className="w-full rounded-md overflow-hidden border-gray-200 border shadow-sm"
              >
                <button className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3">
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 13 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.24404 3.7559C3.24404 1.96349 4.69581 0.511719 6.48822 0.511719C8.28064 0.511719 9.73241 1.96349 9.73241 3.7559C9.73241 5.54832 8.28064 7.00009 6.48822 7.00009C4.69581 7.00009 3.24404 5.54832 3.24404 3.7559ZM0 11.0554C0 8.898 4.32288 7.8112 6.48837 7.8112C8.65387 7.8112 12.9767 8.898 12.9767 11.0554V13.4885H0V11.0554Z"
                    />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Account
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </button>
                <button className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3">
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Privacy
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </button>
                <button className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3">
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-2.995 2.995-7.486 4-11 4 0 8.583 5.068 16.097 11 20 5.932-3.903 11-11.417 11-20-3.514 0-8.005-1.005-11-4z" />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Security &amp; permissions
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </button>
              </div>
            </div>

            <div className="w-full">
              <div className=" w-full flex items-center justify-between text-sm mb-1 sm:text-base">
                <label
                  htmlFor={"subscriptionType"}
                  className="font-semibold text-gray-700"
                >
                  Support &amp; About
                </label>
              </div>
              <div className="w-full rounded-md overflow-hidden border-gray-200 border shadow-sm">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleReviewForm();
                  }}
                  className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3"
                >
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 0H0V15H2V10H7.6L8 12H15V2H9.4L9 0Z" />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Report a problem
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </button>
                <a
                  href={"mailto:info@tknight.live"}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3"
                >
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 20 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.5 23.295V19.695C4.49 19.435 0.5 15.275 0.5 10.205C0.5 4.96499 4.76 0.704987 10 0.704987C15.24 0.704987 19.5 4.96499 19.5 10.205C19.5 15.155 16.06 20.135 10.93 22.605L9.5 23.295ZM11 14.205V16.205H9V14.205H11ZM10.7704 9.52666C9.94212 10.1616 9 10.8837 9 12.705H11C11 11.6096 11.711 10.9883 12.4408 10.3505C13.21 9.67831 14 8.9879 14 7.70499C14 5.49499 12.21 3.70499 10 3.70499C7.79 3.70499 6 5.49499 6 7.70499H8C8 6.60499 8.9 5.70499 10 5.70499C11.1 5.70499 12 6.60499 12 7.70499C12 8.5842 11.4202 9.02859 10.7704 9.52666Z"
                    />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Support
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </a>
                <a
                  href={"https://tknight.live/terms"}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full hover:bg-gray-50 transition-all flex justify-between items-center space-x-3 py-2 px-4 short:px-3"
                >
                  <svg
                    className="aspect-square w-4 fill-current text-gray-400"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 8C0 12.41 3.59 16 8 16C12.41 16 16 12.41 16 8C16 3.59 12.41 0 8 0C3.59 0 0 3.59 0 8ZM7 5V3H9V5H7ZM7 13V7H9V13H7Z"
                    />
                  </svg>
                  <p className="font-semibold flex-1 text-left text-gray-500">
                    Terms and policies
                  </p>
                  <svg
                    className="aspect-square w-3 fill-current text-gray-400"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="pt-2">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-xl relative font-semibold overflow-hidden py-2 transition-all rounded-full shadow-lg text-white px-6"
                onClick={() => {
                  logout();
                }}
              >
                Log out
              </button>
              <p className="text-gray-400 text-center mt-2">v{appVersion}</p>
            </div>
          </div>
        </div>

        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default CustomerAccountSettings;
